import '../styles/header.css';

function Header() {
	return (
		<header className='site-header'>
			<h1>MikkoK</h1>
			<img src='img.png' alt='goblin'></img>
		</header>
	);
}

export default Header;
